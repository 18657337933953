import { Dispatch } from 'redux';

import editCompanyDescriptionFormSubmit from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/edit_company_description_form_submit';

import { EmployerInfoState, setDescription, setDescriptionStatus, Status } from 'src/models/employer/employerInfo';
import { fetcher } from 'src/utils/fetcher';

const SAVE_EMPLOYER_DESCRIPTION_URL = '/employer/description';

declare global {
    interface FetcherPostApi {
        [SAVE_EMPLOYER_DESCRIPTION_URL]: {
            body: { description: EmployerInfoState['description'] };
            queryParams: void;
            response: { description: string };
        };
    }
}

export default (payload: EmployerInfoState['description']) =>
    (dispatch: Dispatch): Promise<void> => {
        dispatch(setDescriptionStatus(Status.Fetching));
        return fetcher.postFormData(SAVE_EMPLOYER_DESCRIPTION_URL, { description: payload }).then(
            ({ data }) => {
                dispatch([setDescription(data.description), setDescriptionStatus(Status.Success)]);
                editCompanyDescriptionFormSubmit();
            },
            (error) => {
                dispatch(setDescriptionStatus(Status.Fail));
                editCompanyDescriptionFormSubmit({ errors: 'TEXT_TOO_SHORT' });
                throw error;
            }
        );
    };
